.my-services .col-md-6 {
    padding-left: 5px;
    padding-right: 5px;
}

.service-item {
    margin-bottom: 10px;
    padding: 50px;
    max-width: 100vw;
    background-color: rgba(250, 250, 250, 0.1);
    transition: all 0.5s;

    &:hover{
        background-color: #51185c80;

        .service-icon{ color: #ffffff; border-color: #ffffff; }
        h4{ color: #ffffff; }
        p{ color: #ffffff; }
    }

    h4, p{
        transition: all 0.5s;
    }

    .service-icon{
        width: 70px;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #c2c2c2;
        border-radius: 50%;
        margin-bottom: 30px;
        font-size: 30px;
        transition: all 0.5s;
    }
}