#login{
    .txt1 {
        font-weight: lighter;
        font-size: 13px;
        line-height: 1.5;
        color: #999999;
        margin: 0px;
        padding: 0px;
        box-sizing: border-box;
        text-align: center;
      }
      
      .txt2 {
        font-weight: normal;
        font-size: 13px;
        line-height: 1.5;
        color: #666666;
        margin: 0px;
        padding: 0px;
        box-sizing: border-box;
        text-align: center;
        text-decoration: none;
        background-color: transparent;
        cursor: pointer;
      }

    .p-t-12 {
        padding-top: 12px;
    }

    .m-l-5 {
        margin-left: 5px;
    }
      
      
      /*//////////////////////////////////////////////////////////////////
      [ login ]*/
      
      .limiter {
        width: 100%;
        margin: 0 auto;
      }
      
      .container-login100 {
        width: 100%;  
        min-height: 100vh;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding: 15px;
        background: #9053c7;
        background: -webkit-linear-gradient(-135deg, #c850c0, #4158d0);
        background: -o-linear-gradient(-135deg, #c850c0, #4158d0);
        background: -moz-linear-gradient(-135deg, #c850c0, #4158d0);
        background: linear-gradient(-135deg, #c850c0, #4158d0);
      }
      
      .wrap-login100 {
        width: 960px;
        background: #fff;
        border-radius: 10px;
        overflow: hidden;
      
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 177px 130px 33px 95px;
      }
      
      /*------------------------------------------------------------------
      [  ]*/
      .login100-pic {
        width: 316px;
      }
      
      .login100-pic img {
        max-width: 100%;
      }
      
      
      /*------------------------------------------------------------------
      [  ]*/
      .login100-form {
        width: 290px;
      }
      
      .login100-form-title {
        font-weight: bold;
        font-size: 24px;
        color: #333333;
        line-height: 1.2;
        text-align: center;
      
        width: 100%;
        display: block;
        padding-bottom: 54px;
      }
      
      
      /*---------------------------------------------*/
      .wrap-input100 {
        position: relative;
        width: 100%;
        z-index: 1;
        margin-bottom: 10px;
      }
      
      .input100 {
        font-size: 15px;
        line-height: 1.5;
        color: #666666;
      
        display: block;
        width: 100%;
        background: #e6e6e6;
        height: 50px;
        border-radius: 25px;
        padding: 0 30px 0 68px;
        border: none;
        outline: none;
      }
      
      
      /*------------------------------------------------------------------
      [ Focus ]*/
      .focus-input100 {
        display: block;
        position: absolute;
        border-radius: 25px;
        bottom: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        box-shadow: 0px 0px 0px 0px;
        color: rgba(87,184,70, 0.8);
      }
      
      .symbol-input100 {
        font-size: 15px;
      
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        position: absolute;
        border-radius: 25px;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding-left: 35px;
        pointer-events: none;
        color: #666666;
      
        -webkit-transition: all 0.4s;
        -o-transition: all 0.4s;
        -moz-transition: all 0.4s;
        transition: all 0.4s;
      }
      
      .input100:focus + .focus-input100 + .symbol-input100 {
        color: #57b846;
        padding-left: 28px;
      }
      
      /*------------------------------------------------------------------
      [ Button ]*/
      .container-login100-form-btn {
        width: 100%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-top: 20px;
      }
      
      .login100-form-btn {
        font-weight: bold;
        outline: none !important;
        border: none;
        font-size: 15px;
        line-height: 1.5;
        color: #fff;
        text-transform: uppercase;
      
        width: 100%;
        height: 50px;
        border-radius: 25px;
        background: #57b846;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 25px;
      
        -webkit-transition: all 0.4s;
        -o-transition: all 0.4s;
        -moz-transition: all 0.4s;
        transition: all 0.4s;
      }
      
      .login100-form-btn:hover {
        background: #333333;
      }
      
      
      
      /*------------------------------------------------------------------
      [ Responsive ]*/
      
      
      
      @media (max-width: 992px) {
        .wrap-login100 {
          padding: 177px 90px 33px 85px;
        }
      
        .login100-pic {
          width: 35%;
        }
      
        .login100-form {
          width: 50%;
        }
      }
      
      @media (max-width: 768px) {
        .wrap-login100 {
          padding: 100px 80px 33px 80px;
        }
      
        .login100-pic {
          display: none;
        }
      
        .login100-form {
          width: 100%;
        }
      }
      
      @media (max-width: 576px) {
        .wrap-login100 {
          padding: 100px 15px 33px 15px;
        }
      }
      
      
      /*------------------------------------------------------------------
      [ Alert validate ]*/
      
      .validate-input {
        position: relative;
      }
      
      .alert-validate::before {
        content: attr(data-validate);
        position: absolute;
        max-width: 70%;
        background-color: white;
        border: 1px solid #c80000;
        border-radius: 13px;
        padding: 4px 25px 4px 10px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        right: 8px;
        pointer-events: none;
      
        color: #c80000;
        font-size: 13px;
        line-height: 1.4;
        text-align: left;
      
        visibility: hidden;
        opacity: 0;
      
        -webkit-transition: opacity 0.4s;
        -o-transition: opacity 0.4s;
        -moz-transition: opacity 0.4s;
        transition: opacity 0.4s;
      }
      
      .alert-validate::after {
        content: "\f06a";
        font-family: FontAwesome;
        display: block;
        position: absolute;
        color: #c80000;
        font-size: 15px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        right: 13px;
      }
      
      .alert-validate:hover:before {
        visibility: visible;
        opacity: 1;
      }

      .p-t-136 {
        padding-top: 136px;
    }
      
      @media (max-width: 992px) {
        .alert-validate::before {
          visibility: visible;
          opacity: 1;
        }
      }
}