.section-portfolio {
    padding-bottom: 120px;
}

.img-res {
    display: block;
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

.portfolio-item {
    height: 280px;
    margin-bottom: 30px;
    position: relative;
}

.portfolio-item:before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    transition: background-color 0.25s ease-in-out;
    -moz-transition: background-color 0.25s ease-in-out;
    -webkit-transition: background-color 0.25s ease-in-out;
    -o-transition: background-color 0.25s ease-in-out;
    -ms-transition: background-color 0.25s ease-in-out;
}

.portfolio-item:hover:before{
    background-color: rgba(33,33,33,0.6);
}

.portfolio-item-info {
    position: absolute;
    top: 150px;
    left: 0px;
    z-index: 999;
    opacity: 0;
    transition: opacity 0.25s ease-in-out, left 0.25s ease-in-out;
    -moz-transition: opacity 0.25s ease-in-out, left 0.25s ease-in-out;
    -webkit-transition: opacity 0.25s ease-in-out, left 0.25s ease-in-out;
    -o-transition: opacity 0.25s ease-in-out, left 0.25s ease-in-out;
    -ms-transition: opacity 0.25s ease-in-out, left 0.25s ease-in-out;
}

.portfolio-item:hover .portfolio-item-info {
    left: 50px;
    opacity: 1;
}

.portfolio-item-info h4 {
    margin: 0 0 20px;
    font-weight: 700;
    color: #fff;
}

.portfolio-item-info a {
    padding: 6px 7px 4px;
    font-size: 12px;
    border: 1px solid #fff;
    border-radius: 50px;
    color: #fff;
    transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out;
    -moz-transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out;
    -webkit-transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out;
    -o-transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out;
    -ms-transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out;
}

.portfolio-item-info a:hover {
    color: #212121;
    background-color: #fff;
}

.section-portfolio .btn {
    margin-top: 10px;
}