.timeline-wrapper {
    position: relative;
    padding: 22px 0;
}
.timeline-wrapper:last-child:before {
    height: 0;
}
.timeline-wrapper:before {
    content: "";
    background: #474559;
    width: 3px;
    height: 100%;
    position: absolute;
    left: 38px;
}

.timeline-yr {
    background: #474559;
    border-radius: 100%;
    position: absolute;
    width: 75px;
    height: 75px;
    line-height: 75px;
    text-align: center;
}
.timeline-yr span {
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    display: block;
    line-height: 75px;
}

.timeline-info {
    display: inline-block;
    vertical-align: top;
    max-width: 432px;
    margin-left: 6em;
}
.timeline-info small {
    color: #e1c0ee;
    font-size: 16px;
    font-weight: bold;
    display: inline-block;
    vertical-align: middle;
    margin-left: 15px;
}

@media (max-width:768px) {
    .resume-padding{
        padding: 15px !important;
    }
}