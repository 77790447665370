#contact{
    padding-bottom: 7rem;
    
    body {
        font-family: "Roboto", sans-serif;
        background-color: #fff;
        line-height: 1.9;
        color: #8c8c8c; }
      
    .text-black {
        color: #000; 
    }
      
    .heading {
        font-size: 2.5rem;
        font-weight: 900; 
    }
      
    .form-control {
        border: none;
        background: #f3f3f3; 
    }
    .form-control:active, .form-control:focus {
        outline: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        border-color: #000;
        background: #f3f3f3; 
    }
      
    .col-form-label {
        color: #000; 
    }
      
    .btn, .form-control, .custom-select {
        height: 50px; 
    }
      
    .custom-select:active, .custom-select:focus {
        outline: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        border-color: #000; 
    }
      
    .btn {
        border: none;
        border-radius: 4px !important; 
    }
    .btn.btn-primary {
        background: #895fdd;
        color: rgb(255, 255, 255);
        padding: 15px 20px; 
    }
    .btn:hover {
        color: #fff; 
    }
    .btn:active, .btn:focus {
        outline: none;
        -webkit-box-shadow: none;
        box-shadow: none; 
    }
      
    .box {
        padding: 40px;
        background: #fff;
        -webkit-box-shadow: -30px 30px 0px 0 rgba(0, 0, 0, 0.08);
        box-shadow: -30px 30px 0px 0 rgba(0, 0, 0, 0.08); 
    }
    .box h3 {
        font-size: 14px;
        margin-bottom: 30px;
        text-align: center;
    }
      
    label.error {
        font-size: 12px;
        color: red; 
    }
      
    #message {
        resize: vertical; 
    }
      
    #form-message-warning, #form-message-success {
        display: none; 
    }
      
    #form-message-warning {
        color: #B90B0B; 
    }
      
    #form-message-success {
        color: #55A44E;
        font-size: 18px;
        font-weight: bold; 
    }
      
    .submitting {
        float: left;
        width: 100%;
        padding: 10px 0;
        display: none;
        font-weight: bold;
        font-size: 12px;
        color: #000; 
    }
      
}