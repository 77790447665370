$logo-size : 2em;
:root {
    --theme: #1d6e7c;
    --theme-2: #3b7553;
}
    
#admin{
    .sidebar{
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        overflow-y: auto;
        z-index: 10;
        background-image: linear-gradient(65deg, var(--theme-2), var(--theme));
        width: $logo-size + 1.6em;
        box-shadow: 2px 2px 7px #0005;
        transition: width .3s, transform .3s;
        overflow-x: hidden;

        &:hover{
            width: 258px;
            ul{
                &.link-section{    
                    .link-section-title{
                        transform: rotateY(0deg) !important;
                        width: auto !important;
                    }
        
                    .link-item{        
                        a{
                            span.link-title{
                                transform: rotateY(0deg) !important;
                                width: auto !important;
                            }
                            i.link-icon{
                                width: 2em !important;
                                text-align: left !important;
                            }
                        }
                    }
                    .chevron-button{
                        display: inline-block !important;
                    }
                    .accordion{    
                        a.navlink{
                            padding-left: 2rem !important;
                        }
                    }
                }
            }

            &::-webkit-scrollbar {
                width: 10px !important;
            }
        }
        
        &.open{
            width: 258px;
        }

        @media (min-width: 992px) {
            &.collapsed{
                ul{
                    &.link-section{    
                        .link-section-title{
                            transform: rotateY(-90deg);
                            width: 0px;
                        }
                        .link-item{        
                            a{
                                span.link-title{
                                    transform: rotateY(-90deg);
                                    width: 0px;
                                }
                                i.link-icon{
                                    width: 100%;
                                    text-align: center;
                                }
                            }
                        }
                        .chevron-button{
                            display: none;
                        }
                        .accordion{    
                            a.navlink{
                                padding-left: 1rem;
                            }
                        }
                    }
                }

                &::-webkit-scrollbar {
                    width: 0px;
                }
            }
        }

        .logo-container{
            display: flex;
        }

        ul{
            letter-spacing: .5px;

            .accordion-btn:hover{
                background-color: #0001;
            }

            &.link-section{
                list-style: none;
                padding-left: 0;

                .link-section-title{
                    color: #dfdfdf;
                    font-weight: bold;
                    text-transform: uppercase;
                    padding: 20px 20px 15px 20px;
                    font-size: .7em;
                    white-space: nowrap;
                    transition: all .3s;
                }

                .link-item{
                    transition: background-color .3s;

                    a{
                        display: block;
                        text-decoration: none;
                        padding: .7rem 1rem;
                        font-size: .9rem;
                        cursor: pointer;
                        color: #ffffffc7;
                        white-space: nowrap;
                        transition: background-color .3s, border .3s;

                        &.active{
                            color: white;
                            background-color: #0003;
                            border-left: 4px solid #00a791;
                        }

                        &:hover{
                            color: #00ffdd;
                        }

                        i.link-icon{
                            width: 2em;
                            transition: all .4s;
                        }

                        span.link-title{
                            display: inline-block;
                            transition: all .3s;
                        }
                    }
                }

                .accordion{
                    background-color: #0002;
                    overflow: hidden;
                    transition: max-height .3s;

                    a.navlink{
                        border-left: 4px solid #0004;
                        padding-left: 2rem;
                        transition: padding .2s;
                    }
                }

                .chevron-button{
                    transform-origin: 50% 50%;
                    transition: transform .3s;

                    &.rotate{
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }

    .logo-container{
        padding: 0.8em;
        display: none;
        justify-content: space-between;

        h6{
            margin: 0;
            display: flex;
            align-items: center;

            img{
                width: $logo-size;
                height: $logo-size;
                object-fit: contain;
                margin-right: .8rem;
            }
        }

        .menu-bars{
            border: none;
            background-color: transparent;
            color: white;
            font-size: 1.2rem;
            cursor: pointer;
        }
    }

    nav{
        display: flex;
        justify-content: space-between;
        padding: 15px;
        background-color: white ;

        ul{
            display: flex;
            align-items: center;
            justify-content: center;
            list-style: none;
            padding-left: 0px;
            margin: 0;

            li{
                margin: 0 7px;

                a{
                    color: #696969;

                    &:hover{
                        color: #474747;
                    }
                }

                .avatar-image{
                    width: 1.5rem;
                    height: 1.5rem;
                }

                form{
                    .search-input{
                        display: flex;
                        width: 2.2rem;
                        height: 2.2rem;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        cursor: pointer;
                        transition: all .3s;

                        &:hover{
                            box-shadow: 2px 2px 10px #182b6b2a;
                        }

                        &.open{
                            border-radius: 7px;
                            width: calc(2.2rem + 263px);
                            box-shadow: 2px 2px 10px #182b6b2a;
                            i{
                                margin-right: 5px;
                            }
                            input[type=search]{
                                width: calc(100% - 2.2rem);
                            }
                        }
                
                        input[type=search]{
                            border: none;
                            padding: 0;
                            caret-color: #289dcc;
                            width: 0px;
                            transition: all .3s;

                            &:focus{
                                outline: none;
                            }

                            &::placeholder{
                                color: #c2c2c2;
                            }
                        }
                    }
                }
            }
        }
    }

    .sidebar-toogle{
        position: fixed;
        height: 58px;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9;
    }

    @media (max-width : 992px) {
        .sidebar{
            width: 258px;

            .logo-container{
                display: none;
            }

            &.open{
                transform: translateX(0%);
            }

            &.collapsed{
                transform: translateX(-100%);
            }
        }

        .logo-container{
            display: flex;
            background-color: var(--theme);
        }
        
        nav ul li form{
            .search-input{
                &.open{
                    width: calc(2.2rem + 163px);
                }
            }
        }
    }

    .main-container{
        position: relative;
        padding-left: 258px;
        transition: padding .4s;
        background-color: #e9e9e9;
        min-height: 100vh;
    
        &.full{
            padding-left: 3.6em;
    
            .sidebar-wrapper{
                display: none;
            }
        }
    
        .main-content{
            padding: 20px;
        }
    
        .sidebar-wrapper{
            position: absolute;
            display: none;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            z-index: 9;
            background-color: #00253d34;
            animation: fade 0.2s linear;
        }
    }

    table{
        td{
            padding: 17px;
        }
    }
    
    @media (max-width: 992px) {
        .main-container{
            padding-left: 0px;
            padding-top: 58px;
        
            &.full{
                padding-left: 0em;
            }
    
            .sidebar-wrapper{
                display: block;
            }
        }
    }
    
    @keyframes fade {
        0% { opacity: 0; }
        100% { opacity: 1; }
    }
}