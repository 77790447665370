#about{
    &.full-screen {
        padding: 8rem 0;
    }
    
    h1, h2, h3, h4, h5, h6 {
        font-weight: bold;
        letter-spacing: -1px;
    }
      
    h1 {
        color: white;
        font-size: 2.8em;
        margin: 24px 0;
    }
      
    h2 {
        color: #353535;
        font-size: 2.4em;
        font-weight: bold;
    }
      
    h3 {
        color: #484848;
    }
      
    h3,
    b, strong {
        font-weight: bold;
    }
      
    .custom-btn {
        background: #eee;
        color: #5b5b5b;
        font-weight: bold;
        border-radius: 50px;
        padding: 13px 29px;
        font-size: 14px;
        line-height: normal;
        overflow: hidden;
        transition: all 0.4s ease;
    }
    .custom-btn:hover {
        color: #ffc200;
    }
    .custom-btn.custom-btn-bg {
        background: #474559;
        color: #ffffff;
    }
    .custom-btn.custom-btn-bg:hover {
        background: #ffc200;
        color: #ffffff !important;
    }
      
    .animated {
        position: relative;
    }
      
    .animated-info {
        display: inline-block;
        vertical-align: top;
        margin-top: 5px;
        min-width: 300px;
        position: relative;
    }
      
    .animated-item {
        color: #ffc200;
    }
      
    .animated-item {
        font-size: 38px;
        line-height: inherit;
        display: block;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        animation: BottomTotop 6s linear infinite 0s;
    }
    .animated-item:nth-child(2n+2) {
        animation-delay: 2s;
    }
    .animated-item:nth-child(3n+3) {
        animation-delay: 4s;
    }

    .about-image{
        position: relative;

        img.img-fluid{
            position: relative;
            z-index: 5;
        }

        img.dots-img{
            position: absolute;
            top: 0;
            left: 0;
            width: 22rem;
            z-index: 1;
            height: auto;
        }
    }
      
    @keyframes BottomTotop {
        0% {
            opacity: 0;
        }
        5% {
            opacity: 0;
            transform: translateY(5px);
        }
        10% {
            opacity: 1;
            transform: translateY(0px);
        }
        25% {
            opacity: 1;
            transform: translateY(0px);
        }
        30% {
            opacity: 0;
            transform: translateY(5px);
        }
        80% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }
    
    @media (max-width: 991px) {
        .full-screen {
            padding-bottom: 4rem;
        }
      
        .color-mode {
            display: none;
        }
      
        .about-image {
            margin-top: 4em;
        }
      
        .mobile-mt-2,
        .contact-form {
            margin-top: 2em;
        }
      
        .contact-info {
            padding: 0 2rem;
        }
    }
    @media (max-width: 767px) {
        h1 {
            font-size: 2.4em;
        }
      
        h2 {
            font-size: 2em;
        }
      
        .animated-item {
            font-size: 28px;
        }
      
        .navbar-collapse {
            background: #ffffff;
            text-align: center;
            padding-bottom: 20px;
        }
      
        .navbar-expand-sm .navbar-nav .nav-link {
            padding: 3px 20px;
        }
    }
    @media (max-width: 580px) {
        .animated-item {
            font-size: 30px;
        }
      
        .custom-btn-group {
            text-align: center;
        }
      
        .custom-btn {
            display: block;
            margin: 10px 0;
        }
      
        .owl-theme .owl-nav {
            display: none;
        }
      
        .timeline-info small {
            display: block;
            margin: 10px 0 0 0;
        }
    }
    @media (max-width: 320px) {
        .animated-text {
            margin-top: 0;
        }
      
        .about-text {
            text-align: center;
        }
      
        .full-screen {
            padding: 4rem 0;
        }
      
        .mobile-block {
            display: block;
        }
      
        .contact-info {
            flex-direction: column;
        }
      
        .social-links li {
            display: inline-block;
            vertical-align: top;
        }
    }    
}